/* Hover Cards: Background Image Cards whose "body" rises up on hover/focus to the element */

export function HoverCards() {
    const cards = document.querySelectorAll('.js__hover-card');

    function toggleActive(targetElement) {
        targetElement.classList.toggle('active');
    }
    function addActive(targetElement) {
        targetElement.classList.add('active');
    }
    function removeActive(targetElement) {
        targetElement.classList.remove('active');
    }

    cards.forEach(card => {
        card.addEventListener('mouseenter', function (event) {
            addActive(card);
        });
        card.addEventListener('focusin', function(event) {
            addActive(card);
        });
        card.addEventListener('mouseleave', function(event) {
            removeActive(card);
        });
        card.addEventListener('focusout', function(event) {
           removeActive(card);
        });
    });
}
