/* Header Navigation Javascript */

export function HeaderNavigation() {

    const body = document.querySelector('body');

    function toggleActive(targetElement) {
        targetElement.classList.toggle('active');

        // If the target element is active, add the overflow-hidden class to the body
        if (targetElement.classList.contains('active')) {

            // Wait for the animation to complete before removing the overflow-hidden class
            setTimeout(() => {
                }, 300);
            body.classList.add('overflow-hidden');
        } else {
            body.classList.remove('overflow-hidden');
        }
    }

    /* Open and close the Search form at the top of the header */
    const searchToggle = document.querySelector('#js__header-search-toggle');
    const searchForm   = document.querySelector('#js__header-search-form');
    const navWrapper = document.querySelector('.header-navigation');

    if (searchToggle && searchForm && navWrapper) {
        searchToggle.addEventListener('click', function(){
            toggleActive(searchForm);
            toggleActive(navWrapper);
        });
    }

    /* Open and close the main navigation menu
        Note: In theory, only the Close *OR* Open should ever be visible at one time.
        In practice, it's probably safer to have both buttons have "toggle" ability,
        so you never get stuck in one menu state.
    */
    const menuPanel = document.querySelector('#js__header-menu-panel');
    const menuClose = document.querySelector('#js__header-menu-close');
    const menuOpen  = document.querySelector('#js__header-menu-open');

    if (menuPanel) {
        if (menuClose) {
            menuClose.addEventListener('click', (event) => {
                toggleActive(menuPanel)
            });
        }

        if (menuOpen) {
            menuOpen.addEventListener('click', (event) => {
                toggleActive(menuPanel)
            });
        }
    }

    /* Image <==> Link Hover Functionality
        On hover to a link, update the side image (if the link has an image)
    */
    const imageTarget = document.querySelector('#js__menu-image-target');
    const imageTriggers = document.querySelectorAll('.menu-item');
    if (imageTriggers && imageTarget) {
        imageTriggers.forEach(item => {
           item.addEventListener('mouseover', (event) => {
               const newSrc = item.dataset.image;
               if (newSrc) {
                   imageTarget.setAttribute('src', newSrc);
               }
           });
        });
    }
}
