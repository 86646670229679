/* Content Builder "Audio Block" playback functionality */

export function AudioBlock() {
    const audioFile = document.querySelector('#js__audio-file');

    if (audioFile) {
        // Variables
        const player = document.querySelector('#js__audio-player');
        const playPauseContainer = player.querySelector('#js__audio-button');
        const playButton = playPauseContainer.querySelector('#js__play-button');
        const pauseButton = playPauseContainer.querySelector('#js__pause-button');
        const timeContainer = player.querySelector('#js__audio-time');
        const currentTime = timeContainer.querySelector('#js__audio-current');
        const totalTime = timeContainer.querySelector('#js__audio-length');
        const durationSlider = player.querySelector('#js__audio-timeline');
        const durationIndicator = durationSlider.querySelector('#js__audio-progress');
        const volumeButton = player.querySelector('#js__volume-button');
        const volumeContainer = player.querySelector('#js__audio-volume-container');
        const volumeWrapper = volumeContainer.querySelector('#js__volume-wrapper');
        const volumeSlider = volumeWrapper.querySelector('#js__volume-slider');
        const volumeInner = volumeSlider.querySelector('#js__volume-inner');
        const volumeOn = volumeButton.querySelector('#js__volume-button-on');
        const volumeOff = volumeButton.querySelector('#js__volume-button-off');
        let volumeAdjusted = false;

        // When the file is first loaded...
        function updateDurationIndicator(position){
            durationIndicator.style.width = `${position}%`;
            audioFile.ontimeupdate = changeTimelinePosition;
        }
        function parseDuration(duration) {
            let seconds = parseInt(duration);
            let minutes = seconds >= 60 ? parseInt(seconds / 60) : 0;
            // If there are minutes, subtract minutes from those seconds to get the remaining seconds, after all
            // the minutes have been doled out
            seconds -= (minutes * 60);
            let hours = minutes >= 60 ? parseInt(minutes / 60) : 0;
            // If there are hours, subtract hours from the minutes
            minutes -= (hours * 60);
            if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
            return `${String(hours).padStart(2, 0)}:${minutes}:${String(
                seconds % 60
            ).padStart(2, 0)}`;
        }
        audioFile.addEventListener('loadeddata', ()=> {
            // Get the total time and fill the `totalTime` with it
            totalTime.textContent = parseDuration(audioFile.duration);
            // And set the volume
            setVolumeIndicator(.5);
        });

        // When the play/pause buttons is clicked...
        playPauseContainer.addEventListener('click', ()=>{
            // Play, pause audio
            if (audioFile.paused) {
                playButton.classList.remove('active');
                pauseButton.classList.add('active');
                audioFile.play();
            } else {
                playButton.classList.add('active');
                pauseButton.classList.remove('active');
                audioFile.pause();
            }

            // Make _extra_ sure the metadata gets initialized (sometimes it doesn't, particularly on mobile)
            // Get the total time and fill the `totalTime` with it
            totalTime.textContent = parseDuration(audioFile.duration);
            if (!volumeAdjusted) {
                // Only set the volume back if the volume HASN'T yet been adjusted
                setVolumeIndicator(.5);
            }
        });

        // While the audio is playing...
        function changeTimelinePosition () {
            // Update the width of the indicator bar
            let percentagePosition = percentagePosition = (100 * audioFile.currentTime) / audioFile.duration;
            updateDurationIndicator(percentagePosition);

            // Update the current time
            currentTime.textContent = parseDuration(audioFile.currentTime);
        }
        audioFile.ontimeupdate = changeTimelinePosition;

        // When the timeline is clicked, update the playback position and the width of the indicator bar
        durationSlider.addEventListener('click', (e)=>{
            const timelineWidth = window.getComputedStyle(durationSlider).width;
            let timeToSeek = e.offsetX / parseInt(timelineWidth) * audioFile.duration;
            console.log(timeToSeek);
            audioFile.currentTime = timeToSeek;
            changeTimelinePosition(timeToSeek);
            currentTime.textContent = parseDuration(audioFile.currentTime);
        });

        // Stop audio at end...
        function audioEnded() {
            // Reset pause icon to play icon
            playButton.classList.add('active');
            pauseButton.classList.remove('active');

            // Reset current time
            currentTime.textContent = parseDuration(0);

            // Reset playback duration indicator
            updateDurationIndicator(0);
        }
        audioFile.onended = audioEnded;

        // Adjust the player volume and indicator position to a specific percent
        function setVolumeIndicator(value) {
            let percent = value * 100;
            volumeInner.style.width = `${percent}%`;
            audioFile.volume = value;

            // If the new volume is 0...
            if (value < .01) {
                // Show the 'mute' icon; hide the 'on' icon
                volumeOn.classList.remove('active');
                volumeOff.classList.add('active');
                audioFile.muted = true;
            } else  {
                // Show the 'on' icon; hide the 'mute' icon
                volumeOn.classList.add('active');
                volumeOff.classList.remove('active');
                audioFile.muted = false;
            }
        }
        // On click to volume button...
        volumeButton.addEventListener('click', ()=>{
            // If the audio is muted already...
            if (audioFile.muted) {
                // Show the 'on' icon; hide the 'mute' icon; set the player volume to 50%; adjust the indicator to 50%
                setVolumeIndicator(.5);
            }
            // Otherwise...
            else {
                // Show the 'mute' icon; hide the 'on' icon; mute the player; adjust the indicator to 0%
                setVolumeIndicator(0);
            }
        });

        // On click to volume slider...
        volumeSlider.addEventListener('click', (e)=>{
            // Calculate the selected volume
            let sliderWidth = window.getComputedStyle(volumeSlider).width;
            let selectedPosition = e.offsetX;
            let calculatedVolume = selectedPosition / parseInt(sliderWidth);
            volumeAdjusted = true;
            // Set the player to that volume; adjust the indicator's width
            setVolumeIndicator(calculatedVolume);
        });
    }
}
