export function NavigationResizeOnScroll() {
    //---- Resize Header on Scroll ----//
    // Reduces height of logo and makes background opaque when scrolling begins

    const header = document.querySelector('.header-navigation');
    const navContainer = document.querySelector('.main-nav-container');
    const logo = document.querySelector('.logo-container');

    let resizing = false;
    let headerShrunk = false;

    function handleScroll() {

        // Debounces resizeHeader()
        if (!resizing) {
            resizing = true;
            // requestAnimationFrame() is a native throttling alternative that helps ensure smooth animation
            requestAnimationFrame(resizeHeader);
        }
    }

    function resizeHeader() {
        // Top of page
        if (window.scrollY === 0) {
            // Increase vertical padding
            navContainer.classList.add('pb-7');
            navContainer.classList.remove('pb-6');
            // Restore full height of logo
            logo.classList.add('max-w-[275px]', 'h-[108px]');
            logo.classList.remove('max-w-[152px]', 'h-[64px]');
            // Toggle flag
            headerShrunk = false;

            // Elsewhere on page (shrinks header after scrolling away from top; fires once)
        } else if (!headerShrunk) {
            // Reduce vertical padding
            navContainer.classList.add('pb-6');
            navContainer.classList.remove('pb-7');
            // Reduce height of logo
            logo.classList.add('max-w-[152px]', 'h-[64px]');
            logo.classList.remove('max-w-[275px]', 'h-[108px]');
            // Toggle flag
            headerShrunk = true;
        }

        resizing = false;
    }

    window.addEventListener('scroll', handleScroll);
}
