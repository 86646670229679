/* Universal javascript */
import '/src/css/app.css';

// Get any third-party libraries
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// Get components
import { HeaderNavigation } from './components/headerNavigation';
import { NavigationResizeOnScroll } from './components/navigationResizeOnScroll';
import { VideoHeroes } from './components/videoHeroes';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

// Delay init of lazyloading plugin
const lazySizesConfig = lazySizes.cfg;
lazySizesConfig.init = false;

// Init Header Navigation
HeaderNavigation();

// Resize Naviation on scroll
NavigationResizeOnScroll();
